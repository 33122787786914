/*
 * @Author: wubo
 * @Date: 2023-06-20 15:04:02
 * @LastEditTime: 2023-10-25 22:22:33
 * @LastEditors: wubo
 * @Description:
 */
import { useEffect, useState, useRef } from 'react';
import { message } from 'antd';
import classNames from 'classnames';
import qs from 'qs';
import { useBeforeUnload, useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { useGoogleOneTapLogin } from '@react-oauth/google';
import {
  appleCodeLoginTokenUsingPOST,
  googleCodeLoginTokenUsingGET,
  marketingActivePOST,
  oneTapLoginTokenUsingGET,
  checkConfirm,
} from '@/services';
import { useCommonStore, useUserStore, useEventStore } from '@/store';
import { useDA, useFetchUsagesInfo, useFetchUserInfo, useGASendEvent } from '@/hooks';
import {
  MEMBERSHIP_ID,
  MEMBERSHIP_ID_MAP,
  MEMBERSHIP_ID_SENDEVENT,
  MEMBERSHIP_ID_MAP_SENDEVENT,
  PAY_PROVIDER,
  PAY_PROVIDER_TEXT,
  STORE_KEY,
  defaultUserInfo,
  GRAY_HEADER_KEY,
  GRAY_HEADER_VALUES,
  PAY3DS_STATUS,
  defaultUserProperties,
  // AB_EXPERIMENTS,
} from '@/common/config';
import LoginModal from './components/LoginModal';
import { landingInfoCache } from '@/layout/BasicLayout';
import { shouldUserUseNewModelName } from '@/common/gray';
import i18next from 'i18next';
import type { DiscountInfo, ResponseType } from '@/type';
import './index.less';
import type { ChildComponentRef } from '@/layout/BasicLayout';
import ToastContent from '@/components/ToastContent';
import { useTranslation } from 'react-i18next';

interface RequireAuthProps {
  children: (props: ChildrenProps & { ref: React.Ref<ChildComponentRef> }) => JSX.Element;
}

interface ChildrenProps {
  openLoginModal?: () => void;
  productYearPrice?: string;
  loading: boolean;
}

const SubscribeEventSuccessMap: { [key: string]: string } = {
  month: 'SubscribeMonthSuccess',
  year: 'SubscribeYearSuccess',
};

const SubscribeEventCancelMap: { [key: string]: string } = {
  month: 'SubscribeMonthCancel',
  year: 'SubscribeYearCancel',
};

const { VITE_APP_CHROME_PLUGIN_REDIRECT_URI, VITE_APP_CHROME_PLUGIN_ID } = import.meta.env;

let sourceUrl = '';

const RequireAuth: React.FC<RequireAuthProps> = (props) => {
  const { children } = props;
  const { sendEvent } = useGASendEvent();
  const {
    fetchUserInfo,
    // fetchUserProperties,
    // fetchGuestInfo
  } = useFetchUserInfo();
  const { sendDAEvent } = useDA();

  const [isLoading, setIsLoading] = useState(true);
  const [loginOpen, setLoginOpen] = useState(false);
  const [productYearPrice, setProductYearPrice] = useState('');
  const {
    userInfo,
    setUserInfo,
    authStatus,
    setAuthStatus,
    setDiscountInfo,
    setUserProperties,
    // setGuestToken,
  } = useUserStore();

  const { sessionId, finishedFirstEnterWebsiteReport, setFinishedFirstEnterWebsiteReport } =
    useEventStore();
  const { requirePayInfo, setRequirePayInfo, setModelList } = useCommonStore();
  const { fetchUsagesInfo } = useFetchUsagesInfo();

  const navigate = useNavigate();
  const location = useLocation();
  const childRef = useRef<ChildComponentRef | null>(null);
  const { t } = useTranslation();
  // const { experiment } = useSundry();

  // const guestFeature = experiment(AB_EXPERIMENTS.GUEST);
  const isHomePage = location.pathname === '/home';
  const searchParams = qs.parse(location.search.replace('?', ''));
  const code = searchParams?.['code'];
  const stripeStatus = searchParams?.['stripeStatus'];
  const stripeSubType = searchParams?.['stripeSubType'];
  const productType = searchParams?.['productType'];
  const payFrom = searchParams?.['payFrom'];
  const fscNext = searchParams?.['fscNext']; // fastspring paypal callback
  const productYearPriceUrl = searchParams?.['productYearPrice'] // stripe callback
    ? decodeURIComponent(searchParams['productYearPrice'] as string)
    : searchParams?.['productYearPrice'];
  const pageFrom = searchParams?.['from']; // pageFrom ='paymentConfirm' 表示从3ds校验回来
  const confirmId = searchParams?.['confirmId']; // 3ds携带的confirmId，用于查询支付状态

  const handleCloseLoginModal = () => {
    setLoginOpen(false);
  };

  const getUserInfo = async (loginType?: string) => {
    try {
      await fetchUserInfo();
      const userData = useUserStore.getState().userInfo;
      // 如果是灰度用户，使用专用的模型列表
      if (shouldUserUseNewModelName(userData) && landingInfoCache?.languageModelList4PCNew) {
        setModelList(landingInfoCache?.languageModelList4PCNew);
      }

      if (fscNext) {
        navigate('/');
        // fastspring paypal转化埋点
        fetchUsagesInfo();
        if (userData.isPro) {
          const subType = [
            MEMBERSHIP_ID.YearPro,
            MEMBERSHIP_ID.YearProPlus,
            MEMBERSHIP_ID.YearUnlimited,
          ].includes(userData.membershipId as number)
            ? 'year'
            : 'month';
          const eventName = SubscribeEventSuccessMap[subType];
          if (!productYearPriceUrl) {
            sendEvent(eventName, {
              provider: PAY_PROVIDER_TEXT[PAY_PROVIDER.Paypal],
              productType: MEMBERSHIP_ID_MAP[userData.membershipId as MEMBERSHIP_ID],
            });
            sendEvent(MEMBERSHIP_ID_SENDEVENT[userData.membershipId as MEMBERSHIP_ID]);
            sendEvent('NewPay_success_status');
          }
        }
      }

      const createdAt = dayjs(userData?.createdAt)?.format('YYYY-MM-DD');
      const formattedToday = dayjs().format('YYYY-MM-DD');
      const isToday = createdAt === formattedToday;
      if (isToday) {
        if (loginType?.includes('google')) {
          sendEvent('Goolgelogin', {
            loginType: loginType,
          });
        }
        if (loginType?.includes('apple')) {
          sendEvent('Applelogin');
        }
        sendEvent('new_login_success');
      }
      sendEvent('Visitwebsite');
    } catch (e) {
      console.error(e);
    }
  };

  const fetchDiscountInfo = async () => {
    try {
      const res = await marketingActivePOST<ResponseType<DiscountInfo>>();
      setDiscountInfo(res.data?.discountCoupon);
    } catch (e) {
      setDiscountInfo(null);
      console.error(e);
    }
  };

  const onLoginSuccess = async (token: string, loginType?: string) => {
    localStorage.setItem(STORE_KEY.Authorization, token);
    await getUserInfo(loginType);
    if (loginType === 'chrome-plugin') {
      try {
        window.chrome.runtime.sendMessage(VITE_APP_CHROME_PLUGIN_ID, {
          event: 'loginSuccess',
          data: {
            userInfo,
            token,
          },
        });
      } catch (e) {
        console.error(e);
      }
    }

    sendEvent('login', {
      loginType,
    });
    handleCloseLoginModal();
    navigate('/');
    fetchDiscountInfo();
  };

  useGoogleOneTapLogin({
    use_fedcm_for_prompt: true,
    disabled:
      !!localStorage.getItem(STORE_KEY.Authorization) || location.pathname?.includes('discover'),
    onSuccess: async (response) => {
      const res = await oneTapLoginTokenUsingGET<ResponseType<string>>({
        idTokenString: response.credential as string,
      });
      sendEvent('fastgooglelogin_success_status');
      onLoginSuccess(res.data, 'google-onetap');
    },
    onError: () => {
      sendEvent('LoginFail', {
        loginType: 'google-onetap',
      });
    },
  });

  useEffect(() => {
    if (payFrom) {
      navigate('/');
      setRequirePayInfo({
        requirePay: requirePayInfo.requirePay + 1,
        errorMsg: '',
        source: 'PLUGIN',
      });
    }
  }, [payFrom]);

  const handleConfirmCheck = async () => {
    try {
      const res = await checkConfirm<ResponseType<string>>({ confirmId: confirmId as string });
      childRef.current?.handleOpenBillingManageModalOpen();
      sendDAEvent('hit3ds_result', {
        action: 1,
        result: res?.data || '',
      });
      if (res?.data === PAY3DS_STATUS.SUCCESSSED) {
        message.open({
          content: <ToastContent icon="success" content={t('common.upgradeSuccessful')} />,
        });
      } else if (res?.data === PAY3DS_STATUS.PROCESSING) {
        message.open({
          content: <ToastContent icon="error" content={t('common.process')} />,
        });
      } else {
        message.open({
          content: <ToastContent icon="error" content={t('common.payment')} />,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (pageFrom && pageFrom === 'paymentConfirm') {
      navigate('/');
      handleConfirmCheck();
    }
  }, [pageFrom]);

  useEffect(() => {
    const fetchInitial = async () => {
      const storedToken = localStorage.getItem(STORE_KEY.Authorization);
      fetchDiscountInfo();
      if (storedToken) {
        await getUserInfo();
      } else {
        setUserInfo({ isLogin: false });
        setUserProperties({ is_login: false });
      }
      setIsLoading(false);
    };
    fetchInitial();
  }, []);

  useEffect(() => {
    handleSendFirstEnterWebsite();
  }, []);

  useEffect(() => {
    if (sessionId && finishedFirstEnterWebsiteReport) {
      sendEvent('enterWebsite');
      sendDAEvent('enterWebsite');
    }
  }, [sessionId]);

  /**
   * enterWebsite 发送的时机为
   * 1、首次进站
   * 2、sessionId过期重新生成时（30分钟内不使用即为过期，会重新生成一个新的sessionId）
   */
  const handleSendFirstEnterWebsite = async () => {
    const searchParams = qs.parse(location.search.replace('?', ''));
    const utm_source = searchParams?.['utm_source'];
    if (utm_source) {
      sourceUrl = window.location.href;
    } else if (window.document.referrer) {
      sourceUrl = window.document.referrer;
    }
    if (!finishedFirstEnterWebsiteReport) {
      await sendEvent('enterWebsite', {
        source_url: sourceUrl,
        default_language: navigator?.language,
        interface_language: i18next.language,
      });
      sendDAEvent('enterWebsite');
      setFinishedFirstEnterWebsiteReport(true);
    }
  };

  useEffect(() => {
    if (authStatus.showLogin && !authStatus.loginOpened && !loginOpen) {
      setLoginOpen(true);
      setAuthStatus({ showLogin: false });
    }
  }, [authStatus, loginOpen]);

  useEffect(() => {
    const getToken = async () => {
      let res: ResponseType<string>;
      if (document.referrer.includes('apple')) {
        res = await appleCodeLoginTokenUsingPOST<ResponseType<string>>(
          {
            code: code as string,
          },
          isHomePage ? { [GRAY_HEADER_KEY]: GRAY_HEADER_VALUES.input } : undefined,
        );
        sendEvent('applelogin_success_status');
        onLoginSuccess(res.data, 'apple');
      } else {
        // chrome plugin login
        res = await googleCodeLoginTokenUsingGET<ResponseType<string>>(
          {
            code: code as string,
            uri: VITE_APP_CHROME_PLUGIN_REDIRECT_URI,
          },
          isHomePage ? { [GRAY_HEADER_KEY]: GRAY_HEADER_VALUES.input } : undefined,
        );
        await onLoginSuccess(res.data, 'chrome-plugin');
      }
    };
    if (code) {
      getToken();
    }
  }, []);

  const stripePaySuccessCb = async () => {
    if (stripeSubType) {
      await fetchUserInfo();
      const { userInfo } = useUserStore.getState();
      const isFreeTrialUser = userInfo?.parsedRoles?.includes('Trail');
      let eventName;
      if (stripeStatus === 'success') {
        fetchUsagesInfo();
        eventName = SubscribeEventSuccessMap?.[stripeSubType as string];
        if (!productYearPriceUrl) {
          // @ts-expect-error ignore
          sendEvent(MEMBERSHIP_ID_MAP_SENDEVENT[productType]);
          sendEvent('NewPay_success_status');
        }

        if (productYearPriceUrl && isFreeTrialUser) {
          setProductYearPrice(productYearPriceUrl as string);
          // stripe-freetrial-支付成功
          sendEvent('Freetrialsuccess', {
            type: productType === MEMBERSHIP_ID_MAP[MEMBERSHIP_ID.YearPro] ? 'Pro' : 'Unlimite',
          });
        }
      }
      if (stripeStatus === 'cancel') {
        eventName = SubscribeEventCancelMap?.[stripeSubType as string];
        if (productYearPriceUrl) {
          // stripe-freetrial-支付失败
          sendEvent('Freetrialcancle', {
            type: productType === MEMBERSHIP_ID_MAP[MEMBERSHIP_ID.YearPro] ? 'Pro' : 'Unlimite',
          });
        }
      }
      if (eventName && !productYearPriceUrl) {
        sendEvent(eventName, {
          provider: PAY_PROVIDER_TEXT[PAY_PROVIDER.Stripe],
          productType,
        });
      }
      navigate(location.pathname);
    }
  };

  useEffect(() => {
    stripePaySuccessCb();
  }, [stripeStatus, stripeSubType, productType]);

  useBeforeUnload(() => {
    const storedToken = localStorage.getItem(STORE_KEY.Authorization);

    if (!storedToken) {
      setUserInfo({ ...defaultUserInfo });
      setUserProperties(defaultUserProperties);
    }
  });

  return (
    <>
      {/* <Spin className="global-loading" spinning={isLoading} size="large"> */}
      <div
        className={classNames({
          'global-container': true,
        })}
      >
        {children({
          openLoginModal: () => setLoginOpen(true),
          productYearPrice: productYearPrice as string,
          loading: isLoading,
          ref: childRef,
        })}
      </div>
      {/* </Spin> */}
      <LoginModal
        open={loginOpen}
        onCancel={handleCloseLoginModal}
        onLoginSuccess={onLoginSuccess}
      />
    </>
  );
};

function withRequireAuth<T>(WrappedComponent: React.FC<T>) {
  const RequireAuthWrapper: React.FC<T> = (rootProps) => {
    return <RequireAuth>{(props) => <WrappedComponent {...rootProps} {...props} />}</RequireAuth>;
  };

  return RequireAuthWrapper;
}

export default withRequireAuth;
