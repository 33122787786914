import { PromptTemplate, MessageItem, HiresImageUrlAndName, Channel } from '@/type';
import { QuoteFilesProps } from './components/QuoteFiles';

export interface SendControllerViewProps {
  isReparseFinish?: boolean;
  /**是否在吐流状态 */
  processing?: boolean;
  /**channel id */
  channelId?: string;
  /**选中的模版信息 */
  template?: PromptTemplate;
  /**输入框placeholder */
  placeholder?: string;
  /**引用文件 */
  quoteFiles?: Pick<QuoteFilesProps, 'title' | 'type'>[];
  /**当前对话类型 */
  chatType?: EChatType[number];
  /**是展示停止发送按钮 */
  stopShow?: boolean;
  /**是否开启联网查询 */
  searchSwitch?: boolean;
  /**关闭引用文件 */
  onCancelQuote?: () => void;
  /**关闭模版 */
  onTemplateClear?: () => void;
  /**发送对话 */
  onSend?: (params: SendParams) => void;
  /** 点击停止发送按钮回调 */
  onStop?: () => void;
  onScreenshot?: () => void;
  onSummary?: () => void;
  /* 输入框内，文字上方的元素，目前只有上传的图片 */
  questionAddons?: React.ReactNode[] | undefined;
  lastMessage?: MessageItem;
  selectedImage?: HiresImageUrlAndName;
  outputLanguageRender?: () => React.ReactNode;
  canScreenShot?: boolean;
  /**pdf截图loading状态 */
  selectedImageProgress?: boolean;
  onClearSelectedImage?: () => void;
  onSuggestionActiveChange?: (active: boolean) => void;
  onSwitchRagChange?: (checked: boolean) => void;
  wrapClassName?: string;
  channel?: Channel;
  switchRagDisabled?: boolean;
  /**输入框位置是否处于底部 */
  isBottom?: boolean;
  /**打开模版预览 */
  openTplPreview?: boolean;
  onTplPreviewClose?: () => void;
}

export enum EChatType {
  /**普通聊天 */
  normal = 'normal',
  /**文档聊天 */
  chatWidthDoc = 'chatWithDoc',
  /**ppt聊天 */
  chatWithPPT = 'chatWithPPT',
  /**creatation */
  chatWithTPL = 'chatWithTPL',
  /**writing */
  writing = 'writing',
}

export interface OneChatFileResponse {
  channel_id?: string;
}

export interface SendParams {
  /**channel类型 */
  channelType?: string;
  channelId?: string;
  prompt?: string;
  searchSwitch?: boolean;
}
