import { AiModelMap, HelmetConfig, FeatureTipInfo, UserFeatureTips } from '@/type';

import { getBasicModelConfig, getProModelConfig } from './modelUtils';
import { UserInfo } from '@/store';
import {
  basicChatModelName,
  basicChatModelNameOld,
  basicDocModelName,
  basicDocModelNameOld,
  proChatModelName,
  proDocModelName,
  gpt4oName,
} from './model';

// 判断是否是pro模型，在某些场景下需要判断，确定是否弹出升级提示
export function isModelPro(model: string) {
  return [proChatModelName, proDocModelName, gpt4oName].includes(model);
}

// 判断模型是不是基础模型
export function isModelBasic(model: string) {
  return [
    basicChatModelName,
    basicDocModelName,
    basicChatModelNameOld,
    basicDocModelNameOld,
  ].includes(model);
}

// 根据是否灰度，获取基础模型名称
export function getBasicModelName(gray: boolean) {
  return gray ? basicChatModelName : basicChatModelNameOld;
}

// 根据是否灰度，获取pro模型名称
export function getProModelName(gray: boolean) {
  return gray ? proChatModelName : proChatModelName;
}

// 判断是否是免费(不限量)模型
export function isModelFree(model: string) {
  return [basicChatModelName].includes(model);
}

export const defaultUserInfo = {
  uid: null,
  email: null,
  username: null,
  // google头像
  picture: null,
  // google头像转base64
  avatar: '',
  isLogin: null,
  isPro: false,
  isProPlus: false,
  isUnlimited: false,
  isTrial: false,
  isNew: null,
  timeRemaining: null,
  membershipId: null,
  membershipExpDate: null,
  stripeCustomer: null,
  fastspringCustomer: null,
  createdAt: null,
  internal: 0,
  partnerStatus: '',
  partnerUid: '',
  refCode: '',
  parsedRoles: [],
};

export const defaultUserProperties = {
  is_login: false,
  user_session_source: '',
  account_name: '',
  product_type: '',
  interval: '',
  subscribe_start_time: '',
  subscribe_expire_time: '',
  membership_id: 0,
};

export const defaultUsageInfo = {
  gpt3: {
    used: 0,
    total: 0,
  },
  gpt4: {
    used: 0,
    total: 0,
  },
  docUploadLimitHint: '',
};

export enum STORE_KEY {
  Userinfo = 'userinfo-storage',
  Authorization = 'authorization-storage',
  Common = 'commons-storage',
  Creation = 'creation-storage',
  Chat = 'chat-storage',
  FileData = 'filedata-storage',
  UpdatePrompt = 'update-prompt',
  PromptNotesNum = 'prompt-notes-num',
  Debug = 'debug-storage',
  Event = 'event-storage',
  HomeSendControl = 'home-send-control-storage',
  Connector = 'connector',
  Language = 'language-storage',
  FeatureTips = 'feature-tips-storage',
  Sundry = 'sundry-storage',
  GuestAuthorization = 'guest-authorization-storage',
  AutoSend = 'auto-send-storage',
}

export enum FEEDBACK_TAG {
  HarmfulOrUnsafe = 0,
  NotTrue = 1,
  Unhelpful = 2,
}

export enum CUSTOMER_JUMP_OPTIONS {
  Stripe = 0,
  Default = 1, // 自主选择
  Fastspring = 2,
}

export const FEEDBACK_TAG_TEXT: { [key: number]: string } = {
  // [FEEDBACK_TAG.HarmfulOrUnsafe]: 'Harmful/Unsafe',
  // [FEEDBACK_TAG.NotTrue]: 'Inaccurate',
  // [FEEDBACK_TAG.Unhelpful]: 'Unhelpful',
  [FEEDBACK_TAG.HarmfulOrUnsafe]: 'layoutFeedbackHarmful',
  [FEEDBACK_TAG.NotTrue]: 'layoutFeedbackInaccurate',
  [FEEDBACK_TAG.Unhelpful]: 'layoutFeedbackUnhelpful',
};

export enum FEEDBACK_TYPE {
  Like = 0,
  Dislike = 1,
  Regernation = 2,
}

export enum FEEDBACK_SHOW_TYPE {
  Like = 'Like',
  Dislike = 'Dislike',
}

export enum PRODUCT_TYPE {
  Free = 'FREE',
  Pro = 'PRO',
  ProPlus = 'PRO+',
  Unlimited = 'Unlimited',
}

// 区分命中了哪个流量
export enum TRIAL_STATUS {
  None = 0,
  Pro = 1, // year pro+ft  表示只有 year pro 能free-trial
  ProAndUnlimit = 2, //year pro+ft + year unlimited+ft  表示只有 year (pro + unlimited) 能free-trial
}

// 国际化标记，value的真实存储在国际化的ENUM中
export const PRODUCT_INFO_SUMMARY: { [key: string]: string } = {
  [PRODUCT_TYPE.Free]: 'layoutPayPRODUCT_TYPE_Free',
  [PRODUCT_TYPE.Pro]: 'layoutPayPRODUCT_TYPE_Pro',
};

// 国际化标记，value的真实存储在国际化的ENUM中
export const NEW_PRODUCT_INFO_SUMMARY: { [key: string]: string } = {
  [PRODUCT_TYPE.Free]: 'layoutPayNEW_PRODUCT_TYPE_Free',
  [PRODUCT_TYPE.Pro]: 'layoutPayNEW_PRODUCT_TYPE_Pro',
  [PRODUCT_TYPE.ProPlus]: 'layoutPayNEW_PRODUCT_TYPE_ProPlus',
  [PRODUCT_TYPE.Unlimited]: 'layoutPayNEW_PRODUCT_TYPE_Unlimited',
};

export const NEW_PRODUCT_INFO_ITEMS_DETAIL = (
  params: {
    type: PRODUCT_TYPE;
    gpt3Quota?: number;
    gpt4Quota?: number;
    pdfPageQuota?: number;
    uploadQuota?: number;
    writeLongQuota?: number;
    writePaperQuota?: number;
    writeExportQuota?: number;
  },
  gray: boolean,
  isFreeModel: boolean,
) => {
  if (params.type === PRODUCT_TYPE.Pro) {
    return [
      {
        title: 'layoutPayNEWPRODUCT_Pro_Title',
        desc: [
          {
            label: 'layoutPayNEWPRODUCT_Pro_label1',
            value: [
              isFreeModel
                ? {
                    text: 'layoutPayNEWPRODUCT_Pro_value1_1_free',
                  }
                : {
                    param: params?.gpt3Quota,
                    text: gray
                      ? 'layoutPayNEWPRODUCT_Pro_value1_1_gray'
                      : 'layoutPayNEWPRODUCT_Pro_value1_1',
                  },
              {
                param: params?.gpt4Quota,
                text: 'layoutPayNEWPRODUCT_Pro_value1_2',
              },
            ],
          },
          {
            label: 'layoutPayNEWPRODUCT_Pro_label2',
            value: [
              {
                param: params?.pdfPageQuota,
                text: 'layoutPayNEWPRODUCT_Pro_value2',
              },
              {
                text: 'layoutPayNEWPRODUCT_Pro_value2_2_1',
              },
              {
                text: gray
                  ? 'layoutPayNEWPRODUCT_Pro_value2_2_2_gray'
                  : 'layoutPayNEWPRODUCT_Pro_value2_2_2',
              },
            ],
          },
          {
            label: 'layoutPayNEWPRODUCT_Pro_label3',
            value: [
              {
                text: 'layoutPayNEWPRODUCT_Pro_value3_3',
              },
              {
                text: 'layoutPayNEWPRODUCT_Pro_value3_4',
              },
              {
                text: 'layoutPayNEWPRODUCT_Pro_value3_5',
              },
              {
                text: 'layoutPayNEWPRODUCT_Pro_value3_6',
              },
            ],
          },
          {
            label: 'layoutPayNEWPRODUCT_Pro_label6',
            value: [
              {
                param: params?.writeLongQuota,
                text: 'layoutPayNEWPRODUCT_Pro_value6_2',
              },
              {
                param: params?.writePaperQuota,
                text: 'layoutPayNEWPRODUCT_Pro_value6_4',
              },
              {
                param: params?.writeExportQuota,
                text: 'layoutPayNEWPRODUCT_Pro_value6_5',
              },
            ],
          },
        ].filter(Boolean),
      },
    ];
  }
  if (params.type === PRODUCT_TYPE.ProPlus) {
    return [
      {
        title: 'layoutPayNEWPRODUCT_ProPlus_Title',
        desc: [
          {
            label: 'layoutPayNEWPRODUCT_ProPlus_label1',
            value: [
              {
                param: params?.gpt3Quota,
                text: gray
                  ? 'layoutPayNEWPRODUCT_ProPlus_label1_1_gray'
                  : 'layoutPayNEWPRODUCT_ProPlus_label1_1',
              },
              {
                param: params?.gpt4Quota,
                text: 'layoutPayNEWPRODUCT_ProPlus_label1_2',
              },
            ],
          },
          {
            label: 'layoutPayNEWPRODUCT_ProPlus_label2',
            value: [
              {
                param: params?.pdfPageQuota,
                text: 'layoutPayNEWPRODUCT_ProPlus_label2_value',
              },
              {
                text: 'layoutPayNEWPRODUCT_ProPlus_label2_4',
              },
              // `Process up to ${params?.pdfPageQuota} pages per PDF`,
              // `Double the GPT-4 query quota`,
            ],
          },
          {
            label: 'layoutPayNEWPRODUCT_ProPlus_label2_3',
            value: [
              {
                text: 'layoutPayNEWPRODUCT_ProPlus_label3',
              },
              {
                text: 'layoutPayNEWPRODUCT_ProPlus_label3_1',
              },
              {
                text: 'layoutPayNEWPRODUCT_ProPlus_label3_2',
              },
              // 'Manage up to 50 active presentation projects',
              // 'Automatic watermark removal',
              // 'Double the usage of advanced templates',
            ],
          },
        ].filter(Boolean),
      },
    ];
  }
  if (params.type === PRODUCT_TYPE.Unlimited) {
    return [
      {
        title: 'layoutPayNEWPRODUCT_Unlimited_Title',
        desc: [
          {
            label: 'layoutPayNEWPRODUCT_Unlimited_label1',
            value: [
              {
                text: gray
                  ? 'layoutPayNEWPRODUCT_Unlimited_value1_1_gray'
                  : 'layoutPayNEWPRODUCT_Unlimited_value1_1',
              },
              {
                text: 'layoutPayNEWPRODUCT_Unlimited_value1_2',
              },
            ],
          },
          {
            label: 'layoutPayNEWPRODUCT_Unlimited_value1_3',
            value: [
              {
                text: 'layoutPayNEWPRODUCT_Unlimited_value2_1',
              },
              {
                param: params?.pdfPageQuota,
                text: 'layoutPayNEWPRODUCT_Unlimited_value2_2',
              },
              {
                text: 'layoutPayNEWPRODUCT_Unlimited_value2_5',
              },
            ],
          },
          {
            label: 'layoutPayNEWPRODUCT_Unlimited_label3',
            value: [
              {
                text: 'layoutPayNEWPRODUCT_Unlimited_value3_3',
              },
              {
                text: 'layoutPayNEWPRODUCT_Unlimited_value3_4',
              },
              {
                text: 'layoutPayNEWPRODUCT_Unlimited_value3_5',
              },
              {
                text: 'layoutPayNEWPRODUCT_Unlimited_value3_1',
              },
            ],
          },
          {
            label: 'layoutPayNEWPRODUCT_Unlimited_label6',
            value: [
              {
                text: 'layoutPayNEWPRODUCT_Unlimited_value6_1',
              },
              {
                text: 'layoutPayNEWPRODUCT_Unlimited_value6_2',
              },
              {
                text: 'layoutPayNEWPRODUCT_Unlimited_value6_3',
              },
            ],
          },
        ].filter(Boolean),
      },
    ];
  }
  return [
    {
      title: null,
      desc: [
        {
          label: 'layoutPayNEWPRODUCT_default_label1',
          value: [
            isFreeModel
              ? { text: 'layoutPayNEWPRODUCT_default_value1_1_free' }
              : {
                  param: params?.gpt3Quota,
                  text: gray
                    ? 'layoutPayNEWPRODUCT_default_value1_1_gray'
                    : 'layoutPayNEWPRODUCT_default_value1_1',
                },
            {
              param: params?.gpt4Quota,
              text: 'layoutPayNEWPRODUCT_default_value1_2',
            },
          ],
        },
        {
          label: 'layoutPayNEWPRODUCT_default_label2',
          value: null,
        },
        {
          label: 'layoutPayNEWPRODUCT_default_label3',
          value: [
            {
              param: params?.uploadQuota,
              text: gray
                ? 'layoutPayNEWPRODUCT_default_value3_0_gray'
                : 'layoutPayNEWPRODUCT_default_value3_0',
            },
            {
              text: 'layoutPayNEWPRODUCT_default_value3_3',
            },
            {
              text: 'layoutPayNEWPRODUCT_default_value3_4',
            },
            {
              text: 'layoutPayNEWPRODUCT_default_value3_5',
            },
            {
              param: params?.writeLongQuota,
              text: 'layoutPayNEWPRODUCT_default_value3_7',
            },
            {
              param: params?.writePaperQuota,
              text: 'layoutPayNEWPRODUCT_default_value3_9',
            },
            {
              param: params?.writeExportQuota,
              text: 'layoutPayNEWPRODUCT_default_value3_10',
            },
          ],
        },
        {
          label: 'layoutPayNEWPRODUCT_default_label4',
          value: null,
        },
      ],
    },
  ];
};

export enum RESPONSE_DATA_CODE {
  Success = 200,
  UsageExceedsLimit = 20001,
  FilePagesExceedsLimit = 20002,
  URL_PARSING_ERROR = 1005,
  FILE_TYPE_UNSUPPORT = 1002,
  InterfaceForbidden = 40001,
  NotStudentEmail = 10001, // 假期学生优惠需求中使用
  VerificationCodeNotMatch = 10002, // 假期学生优惠需求弹窗中使用
  Turnstile = 60001, // 人机验证
  FreeModelTooMany = 50003, // 免费模型使用次数过多
}

export const CUSTOM_RESPONSE_HEADER = 'yj-x-content';

// 国际化标记，value的真实存储在国际化的ENUM中
export enum COLLECT_TEXT {
  // COLLECT_REMOVE = 'Removed from favorites.',
  // COLLECT = 'Added to favorites! You can access it in your recommendation now.',
  COLLECT_REMOVE = 'pagesCreationCOLLECT_REMOVE',
  COLLECT = 'pagesCreationCOLLECT',
}

export const USERSNAP_GLOBAL_API_KEY = '88101e16-8f7f-4be8-ba2c-e4fae78db23f';

export const USERSNAP_PROJECT_API_KEY = '904cc13e-8aae-4c3e-8b8c-b61c0f2ade5d';

export const aiModelMap: AiModelMap = {
  [basicChatModelNameOld]: getProModelConfig({ fontSize: '12px', background: '#19C37D' }),
  [basicDocModelNameOld]: getProModelConfig({ fontSize: '12px', background: '#19C37D' }),
  [basicChatModelName]: getBasicModelConfig({ fontSize: '12px' }),
  [basicDocModelName]: getBasicModelConfig({ fontSize: '12px' }),
  [proChatModelName]: getProModelConfig({ fontSize: '12px' }),
  [proDocModelName]: getProModelConfig({ fontSize: '12px' }),
  [gpt4oName]: getProModelConfig({ fontSize: '12px' }),
};

export enum TPL_TYPE {
  Mine = 'Mine',
  Recommended = 'Recommended',
  All = 'All',
  MyProject = 'MyProject',
  Ppt = 'ppt',
}

/** workspace 路由类型 */
export enum WORKSPACE_ROUTE_TYPE {
  /** 我上传的 */
  Uploaded = 'manual',
  /** 我生成的 */
  Generated = 'ai',
}

/** workspace 文件类型 */
export enum WORKSPACE_FILE_CATEGORY {
  /** 全部 */
  All = 'all',
  Pdf = 'pdf',
  Doc = 'doc',
  Ppt = 'ppt',
  Image = 'image',
  Presentation = 'presentation',
  Writing = 'writing',
}

export enum PRODUCT_PERIOD_TYPE {
  Month = 'month',
  Year = 'year',
}

export enum MEMBERSHIP_ID {
  Free = 0,
  MonthPro = 1,
  // 试用会员
  Trail = 2,
  YearPro = 3,
  MonthUnlimited = 4,
  YearUnlimited = 5,
  MonthProPlus = 6,
  YearProPlus = 7,
  // Guest = 8,
}

export enum PPT_TYPE {
  Outline = 'outline',
  Slides = 'slides',
}

export enum PAY_PROVIDER {
  Stripe = 0,
  Fastspring = 2,
  Paypal = 3,
}

export const PAY_PROVIDER_TEXT: { [key in PAY_PROVIDER]: string } = {
  [PAY_PROVIDER.Stripe]: 'stripe',
  [PAY_PROVIDER.Fastspring]: 'fastspring',
  [PAY_PROVIDER.Paypal]: 'paypal',
};

export const MEMBERSHIP_ID_MAP: { [key in MEMBERSHIP_ID]: string } = {
  [MEMBERSHIP_ID.Free]: 'free',
  [MEMBERSHIP_ID.Trail]: 'trail',
  [MEMBERSHIP_ID.MonthPro]: 'month-pro',
  [MEMBERSHIP_ID.MonthProPlus]: 'month-proplus',
  [MEMBERSHIP_ID.MonthUnlimited]: 'month-unlimited',
  [MEMBERSHIP_ID.YearPro]: 'year-pro',
  [MEMBERSHIP_ID.YearProPlus]: 'year-proplus',
  [MEMBERSHIP_ID.YearUnlimited]: 'year-unlimited',
};

export const MEMBERSHIP_ID_SENDEVENT: { [key in MEMBERSHIP_ID]: string } = {
  [MEMBERSHIP_ID.Free]: '',
  [MEMBERSHIP_ID.Trail]: '',
  [MEMBERSHIP_ID.MonthPro]: 'NewProMonth_pay_success_status',
  [MEMBERSHIP_ID.MonthProPlus]: 'NewPro+Month_pay_success_status',
  [MEMBERSHIP_ID.MonthUnlimited]: 'NewUnlimitedMonth_pay_success_status',
  [MEMBERSHIP_ID.YearPro]: 'NewProYear_pay_success_status',
  [MEMBERSHIP_ID.YearProPlus]: 'NewPro+Year_pay_success_status',
  [MEMBERSHIP_ID.YearUnlimited]: 'NewUnlimitedYear_pay_success_status',
};

// 从stripe支付跳转回来后携带的productType枚举值
export enum MEMBERSHIP_ID_MAP_SENDEVENT {
  'month-pro' = 'NewProMonth_pay_success_status',
  'month-proplus' = 'NewPro+Month_pay_success_status',
  'month-unlimited' = 'NewUnlimitedMonth_pay_success_status',
  'year-pro' = 'NewProYear_pay_success_status',
  'year-proplus' = 'NewPro+Year_pay_success_status',
  'year-unlimited' = 'NewUnlimitedYear_pay_success_status',
}

// 实验的业务代码，实验下线后可删除
export enum EXPERIMENT_CONFIG {
  PdfExperiment = 'a7c5c323-498d-40f2-8784-b6bfed5a862a',
}

export const darkThemes = ['dark', 'blue', 'green'];

export const DEFAULT_OCR_LANGUAGE = {
  langcode: 'en',
  languageEnglish: 'English',
  languageLabel: 'English',
};

export enum DOC_PROMPT_TEMPLATE_ID {
  Insights = 300002,
  Translate = 300003,
  ExtractData = 300004,
}

export const OUTPUT_MODEL = [
  {
    name: 'Basic',
    value: 'modelBasic',
    isShow: true,
  },
  {
    name: proChatModelName,
    value: 'modelGPT4',
    isShow: true,
  },
];

export function getOutPutModel(gray: boolean) {
  return [
    {
      name: gray ? basicChatModelName : basicChatModelNameOld,
      value: 'modelBasic',
      isShow: true,
    },
    {
      name: proChatModelName,
      value: 'modelGPT4',
      isShow: true,
    },
  ];
}

export function getWritingModel(gray: boolean) {
  return [
    {
      name: gray ? basicChatModelName : basicChatModelNameOld,
    },
    {
      name: gpt4oName,
    },
  ];
}

export enum FILE_PARSE_RESULT {
  NotFininsh = 0, // 未完成
  TextFinish = 1, // 文本解析完成
  ImageFinish = 2, // 抽图完成
  TextAndImageFinish = 3, // 抽图和文本解析都完成
}

// 划线价分组枚举
export enum PRICE_STATUS {
  TESTGROUPA = 1, // 实验组A
  TESTGROUPB = 2, // 实验组B
  OTHER = 0, // 其他
}

// 灰度的header的key
export const GRAY_HEADER_KEY = 'popai-flag';
// 灰度的header的value
export const GRAY_HEADER_VALUES = {
  input: 'INPUT_GRAY',
};
export const FAKE_MSG_PROMPT_ID = 'fake-message-prompt-id';

export const FAKE_MSG_ANSWER_ID = 'fake-message-answer-id';

export const FAKE_MSG_MULTI_DOC_ID = 'fake-message-multi-doc-id';

export const FAKE_CHANNEL_PROMPT_ID = 'fake-channel-prompt-id';

export const FAKE_CHANNEL_ANSWER_ID = 'fake-channel-answer-id';

export const getMultiDocMaxCount = (user: UserInfo, guestToken?: string) => {
  if (!user?.isLogin) {
    return guestToken ? 2 : 20;
  }
  return user?.isPro ? 20 : 2;
};

export const DOC_FILE_MIME_WHITE_LIST = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];

export const PPT_FILE_MIME_WHITE_LIST = [
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
];

export enum WRITE_TEMPLATE_ID {
  WritingSummarizeDoc = 120000,
  WritingTranslateDoc = 120001,
  WritingSummarizeSelection = 120002,
  WritingTranslateSelection = 120003,
  WritingFixSpellingAndGrammar = 120004,
  WritingMakeShorter = 120005,
  WritingMakeLonger = 120006,
  // WritingGetAnswer = 120007,
  // WritingExtractQuestion = 120008,
  WritingAskSelection = 120009,
  WritingIntentRecognition = 120013 /** 意图writingid */,
  WritingAdvance = 120014,
}

export const SLASH_CHAR = '/';

export const ZERO_WIDTH_CHAR = '\u200B';

/**
 * 首页，推荐问题类型枚举
 * PBULICITY(1,"创意写作与宣传"),
 * RESEARCH(2,"学术写作与研究"),
 * TOOL(3,"提效工具"),
 */

export enum RECOMMEND_QUESTION {
  // WRITING = 1,
  // SOLVING = 2,
  // CODING = 3,
  // GENERAL = 4,
  // HOTSPOT = 5,
  PBULICITY = 1,
  RESEARCH = 2,
  TOOL = 3,
}

export const RECOMMEND_QUESTION_ICON: { [key in RECOMMEND_QUESTION]: string } = {
  // [RECOMMEND_QUESTION.WRITING]: 'writing',
  // [RECOMMEND_QUESTION.SOLVING]: 'solving',
  // [RECOMMEND_QUESTION.CODING]: 'coding',
  // [RECOMMEND_QUESTION.GENERAL]: 'general',
  // [RECOMMEND_QUESTION.HOTSPOT]: 'hotspot',
  [RECOMMEND_QUESTION.PBULICITY]: 'publicity',
  [RECOMMEND_QUESTION.RESEARCH]: 'research',
  [RECOMMEND_QUESTION.TOOL]: 'tool',
};
export enum THEME_CONFIG_STATUS {
  online = 2,
  offline = 1,
}

export const helmetConfig: { [key: string]: HelmetConfig } = {
  '/': {
    title: 'PopAi - Your Chatgpt AI Assistant Tools',
    meta: [
      { name: 'keywords', content: 'popai, ai tools, chatgpt' },
      {
        name: 'description',
        content:
          'Free trial PopAi now. PopAi is a powerful AI tool that allows you to chat with any doc （GPT-4 & GPT-4o） in one click, create AI presentation with kinds of free templates and generate any AI images fast. Also available on google chrome and apple store.',
      },
    ],
    alternate: `${import.meta.env.VITE_APP_HOST}/m/`,
  },
  '/chatwithdoc': {
    title: 'PopAi chat with any pdf with AI',
    meta: [
      { name: 'keywords', content: 'ai chatpdf' },
      { name: 'description', content: 'Chat with any pdf using PopAi AI pdf reader/summarizer.' },
    ],
    alternate: `${import.meta.env.VITE_APP_HOST}/m/chatwithdoc`,
  },
  '/creation/All/Presentation': {
    title: 'PopAi AI for presentation maker online',
    meta: [
      { name: 'keywords', content: 'ai presentation maker, ai ppt maker' },
      {
        name: 'description',
        content: 'Online generate AI presentation with free new and customizable templates.',
      },
    ],
  },
  '/creation/All/Image': {
    title: 'PopAi AI generated images',
    meta: [
      { name: 'keywords', content: 'ai image generator' },
      { name: 'description', content: 'Create any AI image with PopAi image generator.' },
    ],
  },
  '/writing': {
    title: '',
    meta: [
      { name: 'keywords', content: 'ai writer, ai content generator' },
      {
        name: 'description',
        content:
          'Experience the future of content creation with AI writer, generate content like essay/story/poem effortlessly.',
      },
    ],
  },
};

export enum PAY3DS_STATUS {
  CANCELED = 'canceled',
  PROCESSING = 'processing',
  REQUIRES_ACTION = 'requires_action',
  REQUIRES_CAPTURE = 'requires_capture',
  REQUIRES_CONFIRMATION = 'requires_confirmation',
  REQUIRES_PAYMENT_METHOD = 'requires_payment_method',
  SUCCESSSED = 'succeeded',
}

export const SPACE_CHAR = ' ';

export const DEFAULT_WRITING_JSON: {
  type: string;
  content: any;
} = {
  type: 'doc',
  content: [],
};

export const AUTO_OUTPUT_LANGUAGE = {
  langcode: 'Auto',
  languageEnglish: 'Auto',
  languageLabel: 'Auto',
  languageAr: 'اللغة التلقائية',
  // ocrShow: true,
  // ocrValue: ['eng', 'enm'],
};

export const EN_OUTPUT_LANGUAGE = {
  langcode: 'en',
  languageEnglish: 'English',
  languageLabel: 'English',
  languageAr: 'الإنجليزية',
  ocrShow: true,
  ocrValue: ['eng', 'enm'],
};

export const AR_OUTPUT_LANGUAGE = {
  langcode: 'ar',
  languageEnglish: 'Arabic',
  languageLabel: 'العربية',
  languageAr: 'عربي',
  ocrShow: true,
  ocrValue: ['ara'],
};

export enum TIP_TAG {
  FIRST_SAVE_WRITING = 'firstSaveWriting',
  FIRST_SHOW_USER_PROMPT_TIP = 'firstShowUserPromptTip',
}

/**
 * 二维数组，同一个数组里的tips互斥，只能同时显示1个
 * 排列顺序就是优先级，当两个tip都要显示时，只显示优先级高的那个
 */
export const FEATURE_TIPS_INFO: FeatureTipInfo[][] = [
  [
    // {
    //   tag: 'new-register',
    //   defaultValue: 1,
    // },
    // {
    //   tag: 'standard-free-v2',
    //   defaultValue: 1,
    // },
    // {
    //   tag: 'onechat',
    //   defaultValue: 1,
    //   title: 'components.featureTip.onechat.title',
    //   desc: 'components.featureTip.onechat.desc',
    // },
    // {
    //   tag: 'writing',
    //   defaultValue: 1,
    //   title: 'components.write.sidebarTipsTitle',
    //   desc: 'components.write.sidebarTipsSubTitle',
    //   icon: 'aiWritingSidebarTips',
    // },
    // {
    //   tag: 'workspace',
    //   defaultValue: 1,
    //   title: 'components.featureTip.workspace.title',
    //   desc: 'components.featureTip.workspace.desc',
    //   icon: 'popai-a-workspace1',
    // },
    {
      tag: 'pro',
      defaultValue: 1,
    },
  ],
  [
    {
      tag: TIP_TAG.FIRST_SAVE_WRITING,
      defaultValue: 1,
      title: 'components.write.firstSaveTips',
      icon: 'writingSaveTipIcon',
    },
  ],
  [
    {
      tag: TIP_TAG.FIRST_SHOW_USER_PROMPT_TIP,
      defaultValue: 1,
    },
  ],
];

export const USER_FEATURE_TIPS: UserFeatureTips[] = FEATURE_TIPS_INFO.flat().map((item) => ({
  tag: item?.tag,
  defaultValue: item.defaultValue,
}));

export enum AB_EXPERIMENTS {
  TEAMS = 'popai-teams',
  ONECHAT = 'popai-onechat',
  STANDARD_FREE = 'popai-standard-free',
  STANDARD_FREE_V2 = 'popai-standard-free-v2',
  NEW_REGISTER = 'popai-new-register',
  I18N_AUTOCHANGE_ES = 'popai-i18n-xi',
  GUEST = 'popai-guest',
  UPGRADE = 'popai-pro',
  MEMBERSHIP = 'popai-membership',
}

export const MAX_USER_PROMPT_CONTENT_COUNT = 16000;
export const MAX_USER_PROMPT_NAME_COUNT = 50;

export const NOT_NEED_LOGIN_PUBLICKEY =
  'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC/6ygvACfnVYzxeI5hrEfTZ0D+d40/1crTmLHTa/FHylnkGIvrfrROEcJ+wPLCcMHXtMx4Vi9Uku8QFoMKeDWyc4R9o4We+EbcmPrx3FOXZmHP3eAenc9XSWlwofd/riGLM98XlCeOnZVpPVyXchOCNfA9KdXiGsxEqst75n3L3QIDAQAB';

export const DOC_SEARCH_TPL_ID = '111121';

export const PPT_TYPE_TPL = 'Presentation';
