import CustomIcon from '@/components/CustomIcon';
import { useUserStore } from '@/store';
import { useTranslation } from 'react-i18next';
import { useSundry, useDA } from '@/hooks';
import './DiscountBanner.less';
import { hasCouponActivity } from '@/components/UpgradeButton';
import { AB_EXPERIMENTS, MEMBERSHIP_ID } from '@/common/config';
import { useGlobalModal } from '@/layout/BasicLayout';
import { useCallback, useEffect } from 'react';

interface DiscountBannerProps {
  isShowUpgradeBtn?: boolean;
}

const DiscountBanner: React.FC<DiscountBannerProps> = (props) => {
  const { isShowUpgradeBtn = false } = props;
  const { discountInfo, userInfo } = useUserStore();
  const { checkPayStatus } = useGlobalModal() || {};
  const { t } = useTranslation();
  const { experiment } = useSundry();
  const { sendDAEvent } = useDA();
  const memberShipFeature = experiment(AB_EXPERIMENTS.MEMBERSHIP);

  const showDiscount = hasCouponActivity(discountInfo);

  useEffect(() => {
    if (memberShipFeature && isShowUpgradeBtn) {
      sendDAEvent('activity_banner_btn_show');
    }
  }, [memberShipFeature, isShowUpgradeBtn]);

  const handleClick = useCallback(() => {
    sendDAEvent('activity_banner_btn_click');
    checkPayStatus?.({
      openPriceModal: true,
    });
  }, [checkPayStatus]);

  if (showDiscount) {
    // 显示年折扣率和月折扣率中的最大值
    const discount =
      Math.max(discountInfo?.monthlyDiscount || 0, discountInfo?.yearlyDiscount || 0) * 100;
    return memberShipFeature ? (
      <div className="upgrade-discount-container">
        <div className="upgrade-discount-container-desc">
          <CustomIcon type="giftMember" />
          {`${discountInfo?.desc} `}
          <span>{discount}% OFF !</span>
        </div>
        {discountInfo?.period}
        {isShowUpgradeBtn && !userInfo?.parsedRoles?.includes('Trail') && (
          <div className="upgrade-discount-container-upgrade-btn" onClick={handleClick}>
            {userInfo?.membershipId === MEMBERSHIP_ID.YearUnlimited
              ? t('common.getCoupon')
              : t('components.usageTip.upgrade')}
          </div>
        )}
      </div>
    ) : (
      <div className="discount-container">
        <div className="discount-wrapper">
          <CustomIcon className="gift-icon" type="gift"></CustomIcon>
          <span className="discount-info">
            {`${discountInfo?.desc} ! ${t('common.discountBannerLabel', {
              discount,
            })}`}
          </span>
        </div>
      </div>
    );
  }

  return null;
};

export default DiscountBanner;
